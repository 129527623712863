/*General*/
.user-layout{
    display: inline-block !important;
    max-width: 1300px;
    margin: auto;
    min-height: 51vh !important;
    padding: 5px;

 


}


.user-sider{
    float: left !important;
    display: block ;
    height: 50vh !important;
    max-height: 500px !important;
}

.user-cnt {
     width: calc(100% - 210px);
     padding: 0.1rem;
     overflow: auto;
}

.user-sider{
    min-width: 200px !important;
    display: block ;
    border:solid 1px #d4d4d4!important;
    border-radius: 10px !important;
    padding: 10px 0px;
}


.user-cnt {
    float: left !important;
     width: calc(100% - 210px);
     height: 50vh !important;
     padding: 0.1rem;
     overflow: auto;
}

/*Pedidos*/
.Pedidos{
    width: 100% !important; 
    display: inline-block !important;
    text-align: center !important;
}


.list-pedidos{
     max-width: 900px !important;
     margin: auto !important;
}

.row-pedido{
    background: #FFFFFF !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06) !important;
    border-radius: 12px !important;
    padding: 1rem !important;
}

.list-pedidos-item{
    border-bottom: none !important;
}

.avatar-pedido{
    width: 90%  !important;
    height: 90%  !important;
    min-height:70px !important;
    max-width:  90px !important;
    max-height: 90px !important;
}


.orden-pedido,
    .monto-pedido,
    .guia-pedido{
    
    font-weight: 600 !important;
    line-height: 30px !important;
    margin: 0px !important;
    }

.orden-pedido{
font-size: 18px !important;
color: #000000 !important;
}

.monto-pedido{
font-size: 22px !important;
color: #FF6915 !important;

}
.guia-pedido{
font-size: 11px !important;
color: #FAA227 !important;
}


.ttl-fecha-pedido,
.txt-fecha-pedido{

    color: #747373 !important;
}


.ttl-fecha-pedido{
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 22px !important;


}
.txt-fecha-pedido{ 
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 30px !important;
}

.txt-estatus{
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 22px !important;
    line-height: 37px !important;
    margin-top: 25px !important;
    display: block !important;
}

.est-proceso {  color: #FF6915 !important}
.est-transito{  color: #FEB803 !important}
.est-entregado{ color: #03FE94 !important}



/*Direcciones*/
.Direcciones{
    width: 100% !important; 
    display: inline-block !important;
    text-align: center !important;

}


.list-direcciones{
     max-width: 900px !important;
     margin: auto !important;
}

.row-direccion{
 
    background: #FFFFFF;
    border: 1px solid #FF6915;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 10px;
    text-align: left !important;

}

.list-direcciones-item{
    border-bottom: none !important;
    padding: 10px !important;
}

 

.row-direccion .ant-typography {
    font-style: normal !important; 
    font-weight: 500 !important; 
    font-size: 14px !important; 
    line-height: 22px !important; 
    color: #5C5B5B !important; 
    text-align: left !important;
}

