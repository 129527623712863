
.table-titles .ant-typography{
	color: #FE6E16;
	font-weight: 600;
	font-size: 12px;
}

.list-productos{
	width: 100% !important;
	min-height: 400px;
	margin-bottom: 10px;
	overflow: hidden;
	overflow-y: auto;
}

.list-productos-sm{
	width: 100% !important;
	min-height: 200px;
	margin-bottom: 10px;
	overflow: hidden;
	overflow-y: auto;
}

.tabs-productos{
	background: transparent !important;
}

.tabs-productos .ant-tabs-nav::before{
	border: none !important;
}

.tabs-productos .tag-producto:hover{
	cursor: pointer;
	background: #bfbfbf;
	color: white;
}

.tabs-productos .tag-producto{
	max-width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis 

}

.tabs-productos .scroll-productos{
	width: 100%;
	max-height: 600px;
	min-height: 300px;
	overflow: hidden;
	overflow-y: auto;
}

.producto-card:hover{
	
}

.producto-card:hover .image-producto{
	height: 135px;
	width: 135px;
	transition: width 0.1s;
	transition: height 0.1s;
}

/*
 * @component DrawerOrden
 * @description Estilos para el drawer que se utiliza para editar las ordenes
 */
.drawer-ordenes .ant-drawer-content-wrapper{
	transition: width 1s;
} 

.drawer-ordenes .drawer-container{
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
}

.drawer-ordenes .drawer-container .drawer-productos-wrapper{
	width: 0px;
	overflow: hidden;
	transition: width 1s;
    margin-right: 1rem;
}

.drawer-ordenes .drawer-container .drawer-productos-wrapper.active{
	width: 565px;
} 

.drawer-ordenes .drawer-container .drawer-productos-content{
	width: 565px;
	margin-right: 1rem;
}

.drawer-ordenes .drawer-container .drawer-detalles{
	width: 584px;
	min-width: 584px;
} 

.drawer-ordenes .ant-drawer-header {
	border: none !important;
}

.drawer-ordenes .ant-drawer-body{
	padding: 0px 0rem 0rem 1rem;
}