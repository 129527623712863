
/*
* @component Layout -> Content
* @description Asigna el tamaño del content, se utiliza el calculo de toda la pantalla  menos el tamaño del pageHeader
*/
.layout-admin{

    /*
    * @component Layout -> Content
    * @description Asigna el tamaño del content, se utiliza el calculo de toda la pantalla  menos el tamaño del pageHeader
    */
    .admin-content{
        height: 100%;
    }
    /*
    * @component ProjectManager -> Content
    * @description background color del content de la vista de project manager
    */
    .content-bg {
        background: #F4F8FB !important;
    }

    /*
    * @component ProjectManager -> Col
    * @description background color del col de los card de task
    */

    .col-list-task-bg {
        background: #F2EFF5;
    }

    /*
    * @component CardTaskClient -> List
    * @description Elimina el border-bottom del los items de la lista
    */

    .list-border-none ul .ant-list-item {
        border: none!important;
    }

    /*
    * @component Checkbox 
    * @description Hace el checkbox redondo
    */

    .custom-checkbox-circle .ant-checkbox-checked::after {
        border-radius: 50px!important;
        border: 1.5px solid #2C3E50;
        height: 20px;
        width: 20px;
    }

    /*
    * @component Checkbox 
    * @description Acomoda el input dentro del checkbox
    */

    .custom-checkbox-circle .ant-checkbox {
        left: -3px;
        top: -2px;
    }

    /*
    * @component Checkbox 
    * @description Hace el checkbox inner redondo
    */

    .custom-checkbox-circle .ant-checkbox input, .custom-checkbox-circle .ant-checkbox .ant-checkbox-inner {
        border-radius: 50px;
        border: 1.5px solid #2C3E50;
        height: 20px;
        width: 20px;
    }

    /*
    * @component Divider 
    * @description cambia el color del divider de projectManager
    */

    .border-task {
        border: 1px solid #BDBDFE;
    }

    /*
    * @component RangePicker 
    * @description Hace el ranger transparent con bordes blancos y cuadrado
    */

    .filter-range {
        background: transparent!important;
        border: 1px solid #fff!important;
        color: #fff!important;
        border-radius: 0px !important;
        height: 30px;
    }

    /*
    * @component RangePicker 
    * @description Hace el ranger icon y el placeholder blancos
    */

    .filter-range .ant-picker-input input::placeholder, .filter-range .ant-picker-suffix .anticon>svg,
    .filter-range .ant-picker-input .ant-picker-range-separator .ant-picker-separator .anticon>svg{
        color: #fff!important;
    }

    /*
    * @component RangePicker 
    * @description El borderBottom cuando seleccionas un campo lo pone blanco
    */

    .filter-range .ant-picker-active-bar {
        background: #fff!important;
    }

    /*
    * @component Button 
    * @description Hace el borde transparente
    */

    .button-borderless {
        border: 1px solid transparent!important;
    }

    /*
    * @component Button 
    * @description Boton de descarga de los filtros, lo hace mas grande y el icono blanco
    */

    .button-download, .button-download svg {
        font-size: 25px!important;
        color: #fff;
    }

    /*
    * @component Header 
    * @description Cambia el color del Header y la fuente
    */

    .ant-layout-header {
        color: #FFFFFF;
        line-height: 64px;
        background: #f87934!important;
    }

    /*
    * @component PageHeader 
    * @description Cambia el color del PageHeader y la fuente, 
    * se esta sobreescribiendo la clase nativa ya que
    * no hay variables para modificarlo
    */

    .ant-page-header {
        box-sizing: border-box;
        margin: 0;
        color: #fff;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum';
        position: relative;
        padding: 16px 24px;
        background-color: #8C11FF!important;
    }

    /*
    * @component PageHeader 
    * @description Cambia  la fuente del titulo del PageHeader, 
    * se esta sobreescribiendo la clase nativa ya que
    * no hay variables para modificarlo
    */

    .ant-page-header-heading-title {
        margin-right: 12px;
        margin-bottom: 0;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 3px;
        color: #FFFFFF!important;
        font-weight: 600;
        font-size: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    /*
    * @component Button 
    * @description Ajusta el boton del Upload a la esquina inferior derecha 
    */
    .btn-upload-delete{
        display: block;
        position: absolute!important;
        bottom: 20px;
        right: 0%;
    }

    .header-list{
        text-transform: uppercase;
    }



}

.tipo-tag{
    background: linear-gradient(90deg, #FF6513 3.52%, #FAA227 75.21%) !important;
    border-radius: 22.5px !important;
    width: 100%;
    height: 30px !important;
    text-align: center;
    vertical-align: middle !important;
    color: #FFFFFF !important;
    font-weight: bold;
    line-height: 26px !important;
    
}

.producto-card{
    background: linear-gradient(90deg, #FF6513 3.52%, #FAA227 75.21%) !important;
    border-radius: 8px !important;
    color: #FFFFFF !important;
    text-align: center;
    border: none !important;
    position: relative;
    margin-top: 50px !important;

    .ant-typography{
        color: #FFFFFF !important;
    }

    .image-container{
        height: 100px
    }

    .image-producto{
        height: 130px;
        width: 130px;
        border-radius: 150px;
        background-repeat:no-repeat;
        background-position: center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        position: absolute;
        top: -40px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
}

.carrito-card{


    .clientes{
        width: 80%;
        border-radius: 21px !important;
        
        background: #FFF0E7 !important;
        border-color: unset !important;
        
        .ant-select-selector{
            border: unset !important;
            background-color: #FFF0E7 !important;
            border-radius: 21px !important;
        }
        .ant-select-arrow{
            color: #FE6A15;
        }
        
        .ant-select-dropdown{
            border-radius: 21px !important;
        }

        .ant-select-selection-item{
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0.30000001192092896px;
            color: #000;

        }
    }

    .folio{
        font-family: Poppins;
        font-size: 25px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0px;
        text-align: left;
        color: #FE6A15;

    }

    .utilidad{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #A0A0A0;
    }

    .titulo{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #FE6814;
    }

    .titulo-sm{
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #78797f;
    }

    .dato{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: right;
        color: #333333;
    }

    .generar{
        font-size: 23px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        color: #FAFAFA;
        background: linear-gradient(#F9A528, #FF560E);
        width: 100%;
        height: 50px;
    }

    .tabla{
        border-radius: 10px;
        background: #FFF0E7;
        border-color: transparent;
        width: 100%;
        text-align: center;

    }
}

.cliente-card{
    border-radius: 16px;

    .ant-avatar{
        height: 100px;
        width: 100px;
    }

    .cliente-nombre{
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        max-width: 150px;
    }

    .cliente-credito{
        font-family: Poppins;
        font-size: 10px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0px;
        color: #BFBFBF;
    }

    .cliente-venta{
        font-family: Poppins;
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        color: #FF5C00;
    }

}

.CRMdetalle{
    .detalle{
        min-height: 230px;
    }

    .cliente-title{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0.30000001192092896px;
        color: #121212;
    }

    .cliente-info{
        font-family: Poppins;
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.5px;

        color: #121212;
    }

    .cliente-desde{
        color: #C4CAD3 !important;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.30000001192092896px;

    }

    .direcciones{
        .ant-select-selector{
            border: unset !important;
            background-color: #FFF0E7 !important;
            border-radius: 21px !important;
        }
        .ant-select-arrow{
            color: #FE6A15;
        }
        
        .ant-select-dropdown{
            border-radius: 21px !important;
        }

        .ant-select-selection-item{
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0.30000001192092896px;
            color: #000;

        }

    }

    .info-direccion{
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.30000001192092896px;
        color: #5A5A5A;

    }

    .info-direccion-sub{
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.30000001192092896px;
        color: #5A5A5A;
        display: block !important;

    }

    .venta-title{
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.30000001192092896px;
        color: #121212;

    }

    .venta-dato{
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: 0.30000001192092896px;
        color: #FE6714;


    }

    .info-card{
        .ant-card-body{
            padding: 0.5rem
        }

        .titulo{
            font-family: Poppins;
            font-size: 13px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0.30000001192092896px;
            color: #FF6513 !important;


        }
        .dato{
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0.30000001192092896px;
            color: #888888;

        }
    }


}

.hideTabs {
    .ant-tabs-nav-list{
        display: none !important;      
    } 

}