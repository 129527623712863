 .card-bienvenido {
   background: transparent;
   /* background-image: url("/images/bgBienvenido.png"); */
   background-repeat: no-repeat;
   /* background-size: cover; */
   border-radius: 25px !important;
   border: none !important;
   height: 100px;
   width: 98%;
   max-width: 670px;
 }

 .site-page-header{
   background: linear-gradient(181deg, #F9A528 , #FF560E ) !important;
 }

 .ant-page-header-heading-title{
   color: white !important;
 }




 .main-dashboard-title {
   font-weight: bold;
   font-size: 30px;
   color: #333539;
 }

 .card-text {
   font-weight: 600;
   font-size: 15px;
   color: #333539;
   margin-bottom: 1rem;
 }

 .card-container {
   border-radius: 10px;
   border: none;
   box-shadow: 0px 5px 15px rgba(209, 213, 223, 0.5);
   height: 100%;
   margin-bottom: 0.5rem;
 }

 .card-container .ant-card-head{
   border: none!important;
 }

 .card-container .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
   font-size: 20px;
 }

 .card-icon {
   background: transparent !important;
 }

 .card-count {
   font-size: 25px;
   font-weight: 600;
 }

 .card-icon svg {
   color: #FF6814;
 }

 .icono{
   border-radius: 50% !important;
   height: 35px !important;
   width: 35px !important;
   border: unset !important;
   background-color: #FFF0E8 !important;

    .anticon{
      color:#FF560E !important;
      
    }
 }

 .producto {

  .label{
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #858997;
  }

  .info {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #FF6413;
  }

  .proveedor-label{
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #858997;

  }

  .proveedor{
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.018em;
    color: #858997;

  }
  
  .info-grey {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #858997;
  }

  .mejor {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #00EE0A;
  }

  .peor {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.018em;
    color: #F00D0D;
  }
 }