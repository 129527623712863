/*
 * @component landing-section
 * @description Representa una sección del landing page o de la sección publica
 */
.landing-section {
    margin: 12px auto 24px auto;
}


/*
 * @component landing-section
 * @description Limita el tamaño del website a 1200px
 */
.landing-section.responsive {
    max-width: 1200px;
    width: 100%;
}

