.form-item-container {
    display: inline-block !important;
    width: 50%;
    padding: 6px !important;
}

.ant-form-item {
    margin-bottom: 0px !important;
}

.form-item-container-input {
    background: inherit !important;
    border: none !important;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}

.form-item-container-input .ant-input {
    background: inherit !important;
    border: none !important;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}

.form-item-simple-input {
    background: inherit !important;
    border: none !important;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}

.select-simple .ant-select-selector {
    background: inherit !important;
    border: none !important;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}

/*.payform.responsive .form-item-container{*/
/*        display: inline-block!important;*/
/*        width: 100% !important;*/
/*        padding: 6px !important;*/
/*    }*/

.dynamic-delete-button {
    margin: 8px 8px !important;
    position: absolute;
    font-size: 20px;
}



#paypear-submit {
    display: block;
    margin: 3em auto 0 auto;
    background: #8B10FF !important;
    width: 260px;
}

.w-100 {
    width: 100% !important;
}