.store{
    margin-top: 40px;
    // min-width: 1900px;
}

.store-section.responsive{
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
}

.store-head-section{
    margin-bottom: 24px;
}

.store-icon{
    &-grid-view > *{
        fill: rgba(#FBA228, 0.22);
    }

    &-list-view > *{
        fill: rgba(#FBA228, 0.22);
    }

    &-active > *{
        fill: rgba(#FBA228, 1);
    }
}

.store-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 24px;
    column-gap: 24px;
}

.store-product{
    margin-bottom: 0.75rem !important;
}

.store-card{
    width: 95%;
    margin: 0 5px !important;
    min-width: 150px;
    border-radius: 20px !important;
    position: relative;

    .store-card-tag{
        position: absolute;
        top:20px;
        background-color: #FF6915;
        color: white;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        right: 20px;
        font-weight: 500;
    }
}

.store-card-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0 0px 0;
}

.store-card-button{
    width: 100%;
    height: auto !important;
    padding: 0.5rem 0 !important;
    border-color: #FF6814 !important;
    border-radius: 12px !important;

    & > * {
        color: #FF6814;
    }
}

.store-card-price{
    color: #FF6814 !important;
    font-size: 15px;
}

.store-card-image{
    height: 200px;
    object-fit: cover;
    border-radius: 20px !important;
}

// Corrections
.store-card > .ant-card-body{
    padding: 1.25rem 1rem;
    text-align: center;
}

.store-pagination{
    margin-top: 24px;
}

.store-pagination .ant-pagination-item{
    & * {
        color: #FF6814;
        background-color: rgba(#FF6814, 0.22);
    }
    &:hover{
        border-color: #FF6814;
    }
    &:hover:not(.ant-pagination-item-active) *{
        color:#FF6814;
    }
}

.store-pagination .ant-pagination-item-active{
    border-color: transparent !important;
    & *{
        color: white;
        background-color: rgba(#FF6814, 1);
    }
    &:hover *{
        color: white;
    }
    &:hover{
        border-color: none;
    }
}

.store-pagination .ant-pagination{
    display: flex !important;
    align-items: center;
    justify-content: flex-end;

    & > .ant-pagination-prev, & > .ant-pagination-next, &-options{
        display: none !important;
    }

    & > * {
        display: block !important;
    }

    & > .ant-pagination-total-text {
        flex-grow: 1;
        justify-self: flex-start;
    }
}

.store-pagination .ant-pagination-jump-next, .store-pagination .ant-pagination-jump-prev {
    background-color: rgba(#FF6814, 0.22);

    & * {
        color: #FF6814 !important;
    }
}

.store-pagination .ant-pagination-options{
    display: none !important;

    
}

.store .ant-slider-handle{
    background-color: rgba(#FF6814, 1) !important;
}

.store .ant-slider-track{
    background-color: rgba(#FF6814, 1) !important;
}

.ant-slider-tooltip.ant-tooltip-placement-bottom .ant-tooltip-inner{
    background-color: transparent !important;
    box-shadow: none;
    color: #575757;
}

.ant-slider-tooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow-content{
    background-color: transparent !important;
}

.store-categories{
    margin-top: 2.5rem !important;
}

.store-categories-select{
    width: 100%;
    
}

.store-categories-select > .ant-select-selector{
    height: auto !important;
    padding: 0.5rem 1rem !important;
}


.store-card-title{
    margin: 0.5rem 0 0 0  !important;
    height: 3em;
    font-size: 1em !important;
    overflow: hidden;
}

.store-card-price{
    margin: 0 !important;
    font-size: 1.125rem;
}


.store .producto-title{
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 2rem;
    font-size: 24px;
}

.store .producto-price{
    font-weight: 800 !important;
    color: #FF6814 !important;
    font-size: 22px;
}

.store .iva{
    margin-bottom: 1rem !important;
}

.store .producto-descripcion-title{
    margin-top: 1em;
    padding-left: 10px;
}

.store .producto-descripcion{
    margin-top: 0.5rem;
    border: solid 1px #D0CFCF;
    margin-right: 2rem;
    border-radius: 8px;
    // min-height: 235px;
    max-height: 235px;
    overflow-y: auto; 
    padding-left: 10px;
}



.producto-descripcion{
    border: none !important;
}

.input-compra .controls-producto .control-input-producto .ant-input{
    height: 42px !important;
}

.image-product-container{
    padding-right: 12px;
}

.image-product{
    object-fit: cover;
    height: 100px;
    width: 100%;
}
.image-product-list {
    display: flex;
}

.image-product-list .image-product-container{

    width: 120px;
}