/*
* @component Content
* @description background color del content de las vistas del admin
*/
.content-bg {
    background: #F4F8FB !important;
}

/*
* @component Content
* @description Asigna el tamaño del content
*/
.admin-content{
    min-height: calc(100vh - 100px ) !important;
}

.collapse-productos .ant-collapse-header-text{
    width: 100%;
}

.tabs-personalized{
    margin-top: 1rem !important;
}
.tabs-personalized .ant-tabs-nav-list{
    background: white;
    padding: 0.8rem;
    border-radius: 10px;
}

.tabs-personalized .ant-tabs-tab-active{
    background: #FFDDC8 !important;
    color: #FF6513 !important;
    border: #FFFFFF;
    border-radius: 30px;
    padding: 0 10px;
    width: 120px;
    text-align: center;
    justify-content: center;
    margin: 0 !important;
}
.tabs-personalized .ant-tabs-tab{
    width: 120px;
    margin: 0 !important;
    justify-content: center;
    text-align: center;
}

.tabs-personalized .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #FF6513 !important;
}

.tabs-personalized .ant-tabs-ink-bar{
    display: none;
}


.tabs-personalized-2 .ant-tabs-nav::before{
   border-bottom: none !important;
}
.tabs-personalized-2 .ant-tabs-nav-list{
    background: white;
    padding: 0.8rem;
    border-radius: 10px;
}

.btn-materiales{
    height: 45px !important;
    width: 170px;
    border: none !important;
    box-shadow: none !important;
    font-weight: 600 !important;
}

.btn-materiales.active{
    background: linear-gradient(226.42deg, #F9A528 8.93%, #FF560E 110.98%) !important;
    color: white !important;

}

.tabs-personalized-2 .ant-tabs-tab-active{

    color: white !important;
    border: #FFFFFF;
    background: linear-gradient(226.42deg, #F9A528 8.93%, #FF560E 110.98%) !important;
    border-radius: 10px;
    padding: 0 10px;
    width: 120px;
    text-align: center;
    justify-content: center;
    margin: 0 !important;
}
.tabs-personalized-2 .ant-tabs-tab{
    width: 150px;
    margin: 0 !important;
    justify-content: center;
    text-align: center;
}

.tabs-personalized-2 .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: white !important;
}

.tabs-personalized-2 .ant-tabs-ink-bar{
    display: none;
}

.ant-radio-inner::after{
    margin: 0 !important;
}

.ant-divider{
    border-top:solid 1px #FE6814 !important;
}

.select-estatus .ant-select-selector{
    border: none !important;
    background: #2ACA3A !important;
    border-radius: 15px !important;
}

.select-estatus .ant-select-selector *{
   color: white;
}

.select-estatus  .ant-select-arrow{
    color: white;
}

.chart-semanal div,
.chart-semanal canvas{
    width: inherit !important;
}