/*
 * @component container-we-love-clientes
 * @description Es el estilo de los cards informativos
 */
 .container-we-love-clientes{

    width: 100%;
    display: inline-flex;
    justify-content: space-around;
    margin-top: 2em;
    padding: 0 4rem;

    /*
    * @component card-information
    * @description Es el card de información de un elemento
    */
    .card-information { 
        width: 175px;
        height: 110px;
        border: 1px solid #FF6814;
        border-radius: 14px;
        margin: 10px;
        margin-bottom: 30px !important;

        position: relative;

            /*
            * @component custom-icon
            * @description Es el icono de los cards pequeños- 
            */
        .custom-icon {
            position: absolute;
            background: white;

            left: 50%;
            transform: translateX(-50%);
        }
    }
}

