.avatar-user{
    color: #8B10FF !important;
    margin-right: 10px !important;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center; 
}

.avatar-cliente{
    color: white !important;
    font-size: 22px !important;
    font-weight: 600 !important;
    display: flex !important;
    align-items: center;
}



.row-card{
    background: white !important;
    border-radius: 10px !important;
    padding: 3rem !important;
}

.row-card .ant-col{
    margin-bottom: 15px;
}

.row-card .ant-avatar{
    min-width: 100px !important;
    min-height: 100px !important;
    border-radius: 10px !important;
    font-size: 35px !important;
}

.row-card .ant-avatar .ant-avatar-string{
    padding-top: 30px !important;
}

.ant-input{
    margin-bottom: 5px !important;
}