.analytics {
    border-radius: 15px;
    text-align: center;
    margin: 10px;
    height: 96% !important;
    padding: 10px;

}

.analytics .ant-card-head:first-child {
    background: #FF6814 ;
}

.cardAnalitics .ant-card-head {
    background: #fff !important;
}

.cardAnalitics .ant-card-body {
    color: #000;
    border-radius: 10px !important;
    width: 90% !important;
    margin: 5% !important;
    min-height: 80px;

}

.cardAnalitics .ant-card-body {
    border: 1px solid rgba(170, 80, 254, 0.53) !important;
}

.titulo{
    font-size: 25px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.018em;
    color: #78797F !important;
}

.titulo-dato{
    
    font-size: 35px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.018em;
    color: #1E2029 !important;

}

.subtitulo{
    
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.018em;
    text-align: left;
    color: #78797F !important;

}

.subtitulo-dato{

    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.018em;
    text-align: left;
    color: #1E2029 !important;
}