
/*
* @component Layout -> Content
* @description Asigna el tamaño del content, se utiliza el calculo de toda la pantalla menos el tamaño del header, menos el tamaño del pageHeader
*/
.header-public{
    background: white !important;
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    line-height: unset!important;
    justify-content: center;
    font-family: 'Lato', sans-serif;
    
    .header-section-container{
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        height: auto;

        .header-info{
            float: right;
            @media screen and (max-width: 770px) {
                float: none;
                padding-left: 5px    
            }
        }
    }

    .header-section-container.main{

        @media screen and (max-width: 770px) {
            display: none; 
        }
    }

    .header-section-container.responsive{

        @media screen and (min-width: 770px) {
            display: none; 
        }
    }
    
    .control-bar, .information-bar, .bar-categorias{
        justify-content: center;
        height: auto !important;
    }
    
    .information-bar {
        background: linear-gradient(90deg, #FF6513 3.52%, #FAA227 75.21%);
        height: auto;
        color: white;
        padding: 8px;

        .link-information-bar {
            color: white !important;
        }
        .link-information-bar.hover:hover {
             text-decoration: underline;
        }

        .link-information-bar.divider{
            height: 22px;
            width: 1px;
            background: white;
            display: block
        }
    }
    
    
    .control-bar{
        .input-search {
            .ant-input-group{
                .ant-input {
                    background: #F1F1F1;
                    border-radius: 12px 0 0 12px ;
                    border-color: transparent !important;
                    height: 50px;
                }
                .ant-input-search-button{
                    background: #F1F1F1;
                    border-radius: 0 12px 12px 0 !important;
                    border-color: transparent !important;
                    height: 50px;
                    width: 50px;
                    
                    padding: 0 !important;
                    margin: 0 !important;
                }
            }


            .ant-input-group-addon {
                background: none;
                position: relative;
                top: -3px;
                
            }
        }

        .button-account{
            background: red;
            border: none;
            background: linear-gradient(180deg, #FB9E26 0%, #FF6614 100%);
            border-radius: 10px;
            padding: 10px 20px;
            height: 50px;
            
            display: flex;
            justify-content: center;
            align-items: center;

            .icon-user {
                margin-right: 14px;
                
            }

            span {
                font-size: 13px !important;      
                font-weight: bold;
                max-width: 140px;
                text-overflow: ellipsis;
                overflow: clip;             
            }
        }
    }


    .bar-categorias {

        margin: 1em;

        .category-container {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;

            .link-category{
                color: black;
                font-weight: bold;
                text-align: center;
                font-size: 11px !important;
            }

            .link-category.rest{
                @media screen and (max-width: 809px) {
                    display: none;
                }
            }

        }


        .link-hot-deals {
            position: relative;

            .icon-hot-deals{
                position: absolute;
                left: -22px;
                top: 0px;
            }

        }

    }
}
