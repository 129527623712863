/*
* 
* CSS para los heders del sistema publicos y privados
*
*/

.admin {

	.header-admin{
		padding: 0 !important;
		height: unset !important;
		background: white !important;
	}

	.ant-layout-header{
		line-height: unset !important;
	}
	
	.header-admin .logo-header{
		width: 100%;
		max-width: 250px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px;
		background: linear-gradient(118.77deg, #F9A528 , #FF560E );
	}
	.header-admin .logo-header img{
	
		max-width: 90%;
	}
	
	.header-admin .header-content{
		padding-top: 0.6rem;
	}
	
	.header-admin .ant-input-group-wrapper{
		max-width: 500px;
		margin-left: 1rem;
	}
	
	.notifications-list{
		width: 280px;
	}
	.notification-element{
		height: 70px!important;
	}
	.notification-element .ant-menu-title-content .ant-comment .ant-comment-inner
	.ant-comment-content
	.ant-comment-content-detail ,
	.notification-element .ant-menu-title-content .ant-comment .ant-comment-inner
	.ant-comment-content .ant-comment-actions{
		margin: 0px;
		padding: 0px;
		line-height: 15px;
	}
	
	@media(max-width:835px){
		/* .header-admin .width-100{
			padding-top: 10px;
		} */
		.header-admin .logo-header{
			display: none;
		}
	}

}

.public {

	.header-public{
		display: flex;
		align-items: center;
		min-height: 100px;
	}
	
	.header-public .header-menu{
		width: 100%;
	}
	
	.header-public .header-menu .ant-menu-item{
		color: #fff;
		font-weight: 300;
	}
	
	.header-public .header-menu .ant-menu-item:hover{
		background: transparent;
		color: var(--cyan);
	}
	
	.header-public .header-menu .ant-menu-item-selected{
		background: transparent;
		color: var(--cyan-light);
		font-weight: bold;
	}
	
	.btn-green{
		background: linear-gradient(118.77deg, #2DDA93 0%, #4BD79E 111.54%)!important;
		  box-shadow: 4px 4px 20px rgba(51, 228, 155, 0.7)!important;
		  border-radius: 30px!important;
		  width: 150px!important;
		  height: 40px!important;
		  color: white!important;
		  font-size: 16px!important;
		  border: none!important;
		  /* margin-left: 3rem; */
	}
	.header-public .ant-dropdown-trigger{
		color: white;
		margin-left: 1rem;
	}
	
	.header-public .right{
		margin: 0 0 0 auto;
	}
	.header-public .ant-menu.ant-menu-dark .ant-menu-item-selected,
	.header-public .ant-menu.ant-menu-dark .ant-menu-item:hover{
		background-color: transparent;
	}
	
	@media(max-width:  1174px){
		.header-public .text-cyan-light,
		.header-public .text-white{
			display: none;
		}
	}
	
	@media(max-width:  700px){
		.header-public img{
			display: none;
		}
	}
	@media(max-width:  591px){
		/* .ant-layout-header.header-public{
			padding: 10px;
	
		}
		.header-public .header-menu{
			max-width: inherit!important;
		} */
		.header-public img{
			display: none;
		}
	}
	@media(max-width:  918px){
		.header-public .ant-dropdown-trigger{
			display: none;
		}
	}
	
	@media(max-width:  590px){
		.header-public{
			flex-direction: column;
			height: unset;
		}
	}

}




