@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); 

.landing-slider-slide{
    height: calc(100vh - 150px);
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 120;
}

.titulo-slider{
    font-family: Poppins;
    font-size: 70px;
    font-weight: 600;
    line-height: 100px;
    letter-spacing: 0em;
    text-align: center;
    color:#FFFFFF;


}

.landing-slider-slug{
    font-size: 4.75rem !important;
    color: white !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
    text-align: center !important;
}


@media (max-width: 600px) {
	.landing-slider-slug {
   		font-size: 2.75rem !important;
   		width: 90%;
 	}
}



.landing-slider-brand{
    width: 90%
}

.landing-slider-button-link{
    height: auto !important;
    width: 90%;
    padding: 0.25rem 0 !important;
    border: 4px solid white !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    font-size: 2.125rem !important;
    color: white !important;
    font-weight: 700 !important;
    text-align: center;

}


 
/*section*/

.row-langing-images{
    width: 100% !important;
    text-align: center !important;

}
.row-langing-images > .ant-col{
    text-align: center !important;
    margin: auto !important;
}

.card-landing {
    padding: 0px !important;

    .ant-card-body{
        padding: 12px !important;
    }
    text-align: center !important;
    margin: auto !important;
}

.row-img{
    background-size: cover;
    background-position: center !important;
    height: 700px !important;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding-top: 2.5rem;
    margin: auto !important;
}

.ttl-card-landing{
    color: #FFF !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 2.5em !important;
    line-height: 45px !important;
    text-align: center !important;
    margin-bottom: 40px !important;
}

.btn-card-landing{
    
    background:  transparent !important;
    color: #FFF !important;
    border: 1px solid #FFFFFF !important;
    box-sizing: border-box !important;
    border-radius: 0px !important;
    width: 70% !important;
    max-width: 350px !important;
    height: auto !important;
 

    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 37px !important;
    text-align: center !important;
    color: #FFFFFF !important;

}


.btn-card-landing:hover{
    
    border: 1px solid #FAA227 !important;
    color: #FAA227 !important;

    
 
    text-align: center;
}
 