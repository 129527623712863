.modal-radius .ant-modal-content{
    border-radius: 25px !important;
}

.modal-radius .ant-modal-body{
    border-radius: 25px !important;
    padding:0px !important;
}

.modal-divider, .modal-divider span{
    padding: 0px!important;
    color: rgba(0, 0, 0, 0.45)!important;
}
.modal-list-detalles{
    color: #000;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}